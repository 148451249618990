body {
  background-color: $white;
  color: $black;
}

.landing-main {
  display: flex;
  border-bottom: 1px solid $black;
  position: relative;
  padding-top: 5em;
}

.landing-header-section,
.landing-splash-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 83vh;
  max-width: 50%;
}

.landing-reveal-block-left {
  height: calc(100vh + 5em);
  width: 50vw;
  position: absolute;
  top: -5em;
  left: 0;
  z-index: 2000;
  background-color: $white;
}
.landing-reveal-block-right {
  height: calc(100vh + 5em);
  width: 50vw;
  position: absolute;
  top: -5em;
  right: 0;
  z-index: 2000;
  background-color: $white;
}

.landing-header-section {
  border-right: 1px solid $black;
}

.upper-header-container {
  display: flex;
  flex: 1;
  border-bottom: 1px solid $black;
  max-height: 33.2vh;
}

.upper-header-img,
.upper-header-text {
  flex: 1;
  max-width: 50%;
}

.upper-header-img-container {
  max-height: 100%;
  overflow: hidden;
  transform: scale(0);
  opacity: 0;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
  }
}

.upper-header-img {
  border-right: 1px solid $black;
  padding: var(--padding);
}

.upper-header-text {
  transform: scale(0);
  opacity: 0;
  display: flex;
  align-items: flex-end;
  background-color: $fade-blue;
  padding: var(--padding);
  h4 {
    transform: translateY(30px);
    opacity: 0;
  }
}

.lower-header-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--padding);
  min-height: 55.1vh;
  overflow: hidden;
  p {
    width: 75%;
    opacity: 0;
    transform: translateX(50%) scale(0);
  }
  h1 {
    transform: translateX(100%);
  }
  @media (max-width: 400px) {
    h1 {
      font-size: 30px;
    }
  }
}

.lower-header-container > h1::after {
  content: "";
  display: block;
  height: 240px;
  width: 100%;
  background-color: $white;
  z-index: 100;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  animation-name: header;
  animation-duration: 3s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes header {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(100%);
  }
}

.splash-carousel-container {
  padding: var(--padding);
  max-height: 66.4vh;
  position: relative;
}

.splash-carousel-img-container {
  position: relative;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  transform: scale(0);
  opacity: 0;
  img {
    max-width: 100%;
    display: block;
    object-fit: cover;
    transform: translateY(-25%);
  }
  @media (max-width: 1050px) {
    img {
      transform: translateY(0);
    }
  }
}

.splash-carousel-container {
  flex: 4;
  border-bottom: 1px solid $black;
}

.splash-cta-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $fade-blue;
  padding: var(--padding);
  gap: 0.5em;
  justify-content: space-between;
  transform: scale(0);
  opacity: 0;
  h4 {
    opacity: 0;
  }
}

.get-quote-btn {
  width: 100%;
  border: solid 1px $black;
  padding: 0.5em;
  background-color: $blue;
  color: $white;
  cursor: pointer;
  transform: scale(0);
  &:hover {
    transition: ease-in 0.3s;
    background-color: $white;
    color: $blue;
    border: solid 1px $black;
  }
}

.spacer-container {
  display: flex;
  height: 5em;
  width: 100%;
  border-bottom: solid 1px $black;
}

.spacer-left,
.spacer-right {
  flex: 1;
}

.spacer-left {
  border-right: solid 1px $black;
}

@media (max-width: 800px) {
  .upper-header-container {
    display: none;
  }
  .landing-main {
    flex-direction: column;
  }
  .landing-header-section {
    border-bottom: solid 1px $black;
    justify-content: center;
    max-width: 100%;
  }

  .landing-splash-section {
    max-width: 100%;
  }
  .spacer-left,
  .spacer-right {
    display: none;
  }
  .splash-carousel-container {
    max-height: 50%;
  }
  .splash-carousel-img-container {
    max-height: 50%;
    img {
      max-width: 120%;
    }
  }
  .lower-header-container {
    justify-content: flex-end;
    gap: var(--padding);
  }
}
