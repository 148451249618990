@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@700;900&family=Kantumruy+Pro:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:wght@200;300;400;700&display=swap");

:root {
  //font-size
  --fs-60: 60px;
  --fs-40: 40px;
  --fs-35: 35px;
  --fs-30: 30px;
  --fs-25: 25px;
  --fs-20: 20px;
  --fs-18: 18px;
  --fs-16: 16px;

  //character spacing
  --cs--1-2: -1.2px;
  --cs--1-3: -1.3px;
  --cs--0-8: -0.8px;
  --cs--0-12: -0.12px;
  --cs--0-16: -0.16px;
  --cs--0-18: -0.18px;
  --cs--0-24: -0.24px;
  --cs--0-38: -0.38px;
  --cs--0-44: -1.44px;
  --cs--2: 2px;
  --cs--2-4: 2.4px;

  //font family
  --ff-heading: "Kanit", sans-serif;
  --ff-para: "Kantumruy Pro", sans-serif;

  --padding: 2em;

  @media (max-width: 500px) {
    --padding: 1em;
  }
}
