.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  aspect-ratio: 1/2;
  @media (max-width: 800px) {
    aspect-ratio: 1/1.5;
  }
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 1em;
  color: $white;

  @media (max-width: 800px) {
    gap: 0.2em;
  }
}

.swiper-slide .text {
  max-width: 80%;
  align-content: flex-start;
  padding: 0.1em var(--padding);
  @media (max-width: 950px) {
    max-width: 90%;
    padding-bottom: 5em;
  }
}

.swiper-slide > .subtitle {
  padding: 0.1em var(--padding) 0 var(--padding);
}
.swiper-slide > .title {
  padding: var(--padding) var(--padding) 0 var(--padding);
}

.swiper-pagination {
  bottom: 1em !important;
  @media (max-width: 800px) {
    bottom: 3em !important;
  }
}

.swiper-pagination-bullet {
  width: 1.5em !important;
  height: 1.5em !important;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  border: solid 2px $white !important;
  opacity: 1 !important;
  background: transparent !important;
}

.swiper-pagination-bullet-active {
  background: $white !important;
}
