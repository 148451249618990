.service-navbar {
  display: flex;
  border-bottom: 1px solid $black;
  align-items: center;
  justify-content: space-between;
  height: 5em;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 1000;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  animation: shadow 1.6s forwards 2.5s;
}

@keyframes shadow {
  0% {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  100% {
    -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.45);
  }
}

.service-nav-logo-section {
  display: flex;
  flex: 1;
  height: 100%;
  padding: var(--padding);
}

.service-nav-logo {
  align-self: center;
  margin: 0;
  transform: scale(0);
  opacity: 0;
  animation: serviceNavAni 1s forwards cubic-bezier(0.78, 0.01, 0.21, 1);
  a {
    text-decoration: none;
    color: $black;
    img {
      width: 8em;
    }
  }
}

.service-nav-link-section {
  flex: 2;
  border-right: solid 1px $black;
  height: 100%;
}

.service-nav-links {
  padding: var(--padding);
  display: flex;
  justify-content: flex-end;
  gap: var(--padding);
  li {
    animation: serviceNavAni 1s 0.5s forwards cubic-bezier(0.78, 0.01, 0.21, 1);
    transform: scale(0);
    opacity: 0;
  }
}

.service-nav-socials-section {
  flex: 1;
  height: auto;
  ul {
    align-items: baseline;
    display: flex;
    justify-content: center;
    gap: var(--padding);
    padding: 0 var(--padding);
  }
}

.service-nav-socials {
  li {
    animation: serviceNavAni 1s 0.8s forwards cubic-bezier(0.78, 0.01, 0.21, 1);
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes serviceNavAni {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 880px) {
  .service-nav-logo-section {
    border: none;
  }
  .service-nav-links {
    justify-content: space-around;
  }
  .service-nav-socials-section {
    display: none;
  }
}

@media (max-width: 800px) {
  .service-nav-link-section {
    display: none;
  }
}
