.service-main {
  display: flex;
  height: 83vh;
  flex-direction: column;
  position: relative;
}

.service-upper-section {
  display: flex;
  flex: 1;
  border-bottom: solid 1px $black;
  position: relative;
  max-height: 16.6vh;
}

.service-upper-space {
  animation: backColor 0.5s 0.1s forwards;
  background-color: $white;
  h4 {
    transform: scale(0);
    animation: spaceWords 0.7s 0.5s forwards;
  }
}

@keyframes backColor {
  0% {
    background-color: $white;
  }
  100% {
    background-color: $fade-blue;
  }
}

@keyframes spaceWords {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.service-lower-section {
  display: flex;
  flex: 4;
  border-bottom: solid 1px $black;
  position: relative;
  max-height: 66.4vh;
  width: 100%;
}

.service-header-section {
  flex: 3;
  display: flex;
  border-right: solid 1px $black;
  align-items: center;
  padding: var(--padding);
}

.service-header-p {
  animation: animateP 1.5s forwards;
  transform: translateX(-100%);
  overflow: hidden;
  p {
    transform: translateX(200%);
    animation: animateWord 1.5s forwards;
  }
}

@keyframes animateP {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animateWord {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}

.service-header-h1 {
  overflow: hidden;
  transform: translateX(-100%);
  animation: animateP 1.5s forwards;

  h1 {
    transform: translateX(200%);
    animation: animateWord 1.4s forwards 0.1s;
  }
}

.service-header-container {
  color: $blue;
}

.service-upper-space {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    background-color: $blue;
    color: $white;
    padding: 0.5em;
    border: none;
  }
}

.service-carousel-section {
  flex: 3;
  border-right: solid 1px $black;
  padding: var(--padding);
  position: relative;
  height: 100%;
  width: 75vw;
}

.service-img-container {
  position: relative;
  overflow: hidden;
  height: 0%;
  max-width: 100%;
  animation: splashImg 1.8s 0.2s forwards;
  img {
    width: 100%;
    object-fit: cover;
    max-height: 100%;
  }
}

@keyframes splashImg {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

.service-slide-img {
  height: 100%;
  padding: var(--padding);
  background-size: cover;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: $fade-black;
    opacity: 0.7;
  }
  div {
    mix-blend-mode: hard-light;
  }
}

.service-info-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.service-info-upper-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px $black;
  background-color: $blue;
  color: $white;
  padding: var(--padding);
  justify-content: space-between;
  gap: var(--padding);
  max-height: 33.2vh;
  transform: scale(0);
  opacity: 0;
  animation: serviceNavAni 1.5s forwards cubic-bezier(0.78, 0.01, 0.21, 1);
  button {
    display: none;
    border: none;
    background-color: $white;
    color: $blue;
    padding: 0.5em;
  }
}

.call-btn-text {
  text-decoration: none;
  color: $blue;
}

.service-info-lower-section {
  flex: 1;
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1em;
}

.service-info-bottom,
.service-info-top {
  p {
    opacity: 0;
    animation: serviceP 2.2s 0.5s forwards cubic-bezier(0.78, 0.01, 0.21, 1);
  }
}

@keyframes serviceP {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 950px) {
  .service-upper-space {
    display: none;
  }
  .service-lower-section {
    flex-direction: column;
    border: none;
  }
  .service-info-upper-section {
    button {
      display: block;
    }
  }
  .service-carousel-section {
    width: 100%;
  }
}

.service-btn {
  cursor: pointer;
  transition: ease-in 0.2s;
  &:hover {
    background-color: $white;
    color: $blue;
    border: solid 1px $blue;
  }
}
