.services-main {
  display: flex;
  border-bottom: solid 1px $black;
}

.services-sideinfo-container,
.services-services-container {
  min-height: 83vh;
}

.services-sideinfo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: var(--padding);
  background-color: $fade-blue;
  border-right: solid 1px $black;
}

.services-services-container {
  display: flex;
  flex-direction: column;
  flex: 3;
}

.services-upper-container,
.services-lower-container {
  display: flex;
  flex: 1;
  max-height: 41.5vh;
  position: relative;
}

.services-upper-container {
  border-bottom: solid 1px $black;
}

.service {
  padding: var(--padding);
}

.upper-left-service {
  flex: 1;
  border-right: solid 1px $black;
}

.upper-right-service {
  flex: 2;
}

.lower-left-service {
  flex: 2;
  border-right: solid 1px $black;
}

.lower-right-service {
  flex: 1;
  background-color: $fade-blue;
  padding: 3.25em;
}

.upper-left-img-container {
  height: 100%;
  position: relative;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 180%;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(12, 98, 199, 0.5) 100%
    );
  }
}

.upper-right-img-container,
.lower-left-img-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  img {
    width: 125%;
    object-fit: cover;
  }
  img:hover {
    transform: scale(1.1);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(12, 98, 199, 0.5) 100%
    );
  }
}

.service-img-text {
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  color: $white;
  z-index: 10;
}

.services-spacer-container {
  display: flex;
  height: 5em;
  width: 100%;
  border-bottom: solid 1px $black;
}

.services-spacer-left {
  flex: 3;
  border-right: solid 1px $black;
  padding: var(--padding);
}
.services-spacer-right {
  flex: 1;
  padding: var(--padding);
}

.title {
  h1 {
    opacity: 0.25;
    position: absolute;

    font-size: 250px;
  }
}

@media (max-width: 1250px) {
  .upper-right-img-container,
  .upper-left-img-container,
  .lower-left-img-container {
    img {
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
    }
  }
  .upper-right-service {
    flex: 1;
  }
  .lower-right-service {
    display: none;
  }
}

@media (max-width: 800px) {
  .services-main {
    flex-direction: column-reverse;
  }
  .services-sideinfo-container {
    min-height: max-content;
    gap: 1em;
    border-top: solid 1px $black;
  }
  .services-upper-container {
    flex-direction: column;
    max-height: 50%;
  }
  .upper-right-img-container,
  .upper-left-img-container,
  .lower-left-img-container {
    height: 25vh;
  }

  .upper-left-service {
    border-bottom: solid 1px $black;
  }
  .services-spacer-left {
    display: none;
  }
}
