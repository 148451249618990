.contacts-main {
  display: flex;
  border-bottom: solid 1px $black;
  max-height: 83vh;
}

.contacts-splash-section {
  flex: 3;
  border-right: solid 1px $black;
  padding: var(--padding);
}

.contacts-img-container {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.contacts-info-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  background-color: $blue;
  color: $white;
  padding: var(--padding);
  text-align: center;
  @media (max-width: 400px) {
    h2 {
      font-size: 30px;
    }
  }
}

.contacts-details-link {
  text-decoration: none;
  color: $white;
}

.contacts-details {
  padding: var(--padding) 0;
  p {
    line-height: 1;
  }
}

@media (max-width: 1250px) {
  .contacts-details {
    h4 {
      letter-spacing: 1px;
    }
  }
}

.contacts-spacer-container {
  display: flex;
  height: 5em;
  width: 100%;
  border-bottom: solid 1px $black;
}

.contacts-spacer-left {
  flex: 3;
  border-right: solid 1px $black;
  padding: var(--padding);
}
.contacts-spacer-right {
  flex: 1;
  padding: var(--padding);
}

@media (max-width: 950px) {
  .contacts-main {
    flex-direction: column;
  }
  .contacts-info-section {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    text-align: left;
  }
  .contacts-spacer-container {
    display: none;
  }
}
