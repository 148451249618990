.about-main {
  display: flex;
  flex-direction: column;
  min-height: 83vh;
}

.about-upper-section,
.about-lower-section {
  display: flex;
}

.about-upper-section {
  flex: 1;
  border-bottom: solid 1px $black;
  max-height: 33.2vh;
}

.about-lower-section {
  flex: 1.5;
  position: relative;
  max-height: 55.1vh;
  border-bottom: solid 1px $black;
}

.about-heading-section,
.about-blurb-section {
  flex: 1;
}

.about-heading-section {
  display: flex;
  border-right: solid 1px $black;
}

.about-heading {
  display: flex;
  flex: 1;
  border-right: solid 1px $black;
  padding: var(--padding);
  background-color: $blue;
  color: $white;
  align-items: center;
}

.about-heading-space {
  flex: 1;
  padding: var(--padding);
}

.about-blurb-section {
  display: flex;
  background-color: $fade-blue;
}

.about-blurb {
  padding: var(--padding);
  flex: 1;
}

.about-main-img-section {
  flex: 3;
  padding: var(--padding);
  border-right: solid 1px $black;
  position: relative;
  width: 100%;
}

.about-main-img-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
  @media (max-width: 1250px) {
    img {
      object-position: top right;
      width: 140%;
    }
  }
}

.about-side-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: var(--padding);
}

.about-side-img-section {
  flex: 2;
  position: relative;
  max-height: 22.55vh;
}

.about-side-img-container {
  height: 100%;
  overflow: hidden;
  img {
    width: 100%;
  }
  @media (max-width: 1250px) {
    img {
      object-fit: cover;
      object-position: top left;
      width: 100%;
      height: 100%;
    }
  }
}

.about-side-space-section {
  flex: 1;
  background-color: $fade-blue;
  margin: 2em -2em -2em -2em;
  border-top: solid 1px $black;
}

.about-spacer-container {
  display: flex;
  height: 5em;
  width: 100%;
  border-bottom: solid 1px $black;
}

.about-spacer-left {
  flex: 3;
  border-right: solid 1px $black;
  padding: var(--padding);
}
.about-spacer-right {
  flex: 1;
  padding: var(--padding);
}

@media (max-width: 800px) {
  .about-upper-section {
    flex-direction: column;
    max-height: 100%;
  }
  .about-heading-space {
    display: none;
  }

  .about-blurb-section {
    flex-direction: column;
  }
  .about-blurb-2 {
    display: none;
  }

  .about-side-section {
    display: none;
  }
  .about-main-img-container {
    img {
      object-fit: cover;
      height: 100%;
      object-position: 70%;
    }
  }
  .about-spacer-right {
    display: none;
  }
}
