.projects-main {
  display: flex;
  flex-direction: column;
  min-height: 83vh;
  position: relative;
}

.projects-splash-container {
  flex: 4;
  border-bottom: solid 1px $black;
  padding: var(--padding);
  max-height: 66.4vh;
  overflow: hidden;
  position: relative;
}

.projects-splash-img-container {
  max-width: 100%;
  max-height: calc(66.4vh - 4em);
  overflow: hidden;
  text-align: center;
  img {
    object-fit: cover;
    aspect-ratio: 1/3;
    @media (min-width: 1551px) {
      width: 100%;
      aspect-ratio: auto;
    }
  }
  @media (max-width: 1250px) {
    img {
      object-fit: cover;
      object-position: 50% 50%;
      height: 100%;
    }
  }

  @media (max-width: 800px) {
    img {
      aspect-ratio: 1/2;
      object-position: bottom;
    }
  }
}

.testimonial-container {
  padding: 1em;

  position: absolute;
  top: var(--padding);
  left: var(--padding);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(12, 98, 199, 0.5) 100%
  );
  width: calc(100% - 4em);
  height: calc(100% - 4em);
  padding: var(--padding);
  h4 {
    color: $white;
    font-weight: 400;
  }
  a {
    text-decoration: none;
    font-weight: bold;
    color: $white;
    cursor: pointer;
  }
  @media (max-width: 500px) {
    width: calc(100% - 2em);
    height: calc(100% - 2em);
  }
}

.testimony {
  p {
    color: $fade-white;
    font-weight: bold;
  }
  margin-top: 4em;
  margin-bottom: auto;
  @media (max-width: 500px) {
    margin-top: 2em;
    h1 {
      font-size: 40px;
    }
  }

  @media (max-height: 680px) {
    h1 {
      font-size: 30px;
    }
  }
}

.projects-info-container {
  flex: 1;
  display: flex;
  border-bottom: solid 1px $black;
  align-items: center;
}

.projects-header-container {
  flex: 3;
  padding: var(--padding);
}

.projects-blurb-container {
  flex: 1;
  padding: var(--padding);
  border-left: solid 1px $black;
  background-color: $blue;
  color: $white;
}

.projects-spacer-container {
  display: flex;
  height: 5em;
  width: 100%;
  border-bottom: solid 1px $black;
}

.projects-spacer-left {
  flex: 1;
  border-right: solid 1px $black;
  padding: var(--padding);
}
.projects-spacer-right {
  flex: 3;
}

@media (max-width: 800px) {
  .projects-main {
    flex-direction: column-reverse;
  }
  .projects-info-container {
    flex-direction: column;
  }
  .projects-header-container {
    border-bottom: solid 1px $black;
  }
  .projects-spacer-left,
  .projects-spacer-right {
    display: none;
  }
}
