.navbar {
  display: flex;
  border-bottom: 1px solid $black;
  align-items: center;
  justify-content: space-between;
  height: 5em;
  position: fixed;
  width: 100vw;
  top: 0;
  background-color: $white;
  z-index: 1000;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  animation: shadow 1.6s forwards 2.5s;
}

@keyframes shadow {
  0% {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  100% {
    -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.45);
  }
}

.nav-logo-section {
  display: flex;
  flex: 1;
  height: 100%;
}

.check-img {
  width: 25px;
}

.nav-logo {
  align-self: center;
  margin: 0;
  padding-left: var(--padding);
  transform: scale(0);
  opacity: 0;
  width: 2rem;
  a {
    text-decoration: none;
    color: $black;
    img {
      width: 8em;
    }
  }
}

.nav-link-section {
  flex: 2;
  border-right: $black 1px solid;
  display: grid;
  align-content: center;
  min-height: 100%;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  padding: 0 var(--padding);
  li {
    opacity: 0;
    transform: scale(0);
  }
}

.nav-socials-section {
  flex: 1;
  height: auto;
  padding: 0 1.3em;
  ul {
    align-items: baseline;
    display: flex;
    justify-content: center;
    gap: var(--padding);
  }
}

.nav-socials {
  li {
    opacity: 0;
    transform: scale(0);
  }
}

.nav-toggle {
  position: absolute;
  top: 1.9em;
  right: 2em;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 1.2em;
  z-index: 1000;
}

.nav-toggle > .bar {
  height: 4px;
  width: 100%;
  background-color: $black;
  transition: 0.5s;
}

.nav-toggle :nth-child(1) {
  transform: skewX(-30deg) translateX(1px);
}

#top-bar {
  -webkit-transform: rotate(150deg);
  -moz-transform: rotate(150deg);
  -o-transform: rotate(150deg);
  transform: rotate(150deg) skew(-30deg);
  transition: 0.5s;
}

#mid-bar {
  transform: translateX(-100%);
  opacity: 0;
  transition: 0.5s;
}

#low-bar {
  -webkit-transform: rotate(-150deg);
  -moz-transform: rotate(-150deg);
  -o-transform: rotate(-150deg);
  transform: rotate(-150deg) skew(30deg);
  transition: 0.5s;
}

.nav-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  top: 5em;
  left: 0;
  background-color: $white;
  color: $black;
  border-bottom: solid 1px $black;
  z-index: 500;
  -webkit-transition: 1s cubic-bezier(0.78, 0.01, 0.21, 1);
  -o-transition: 1s cubic-bezier(0.78, 0.01, 0.21, 1);
  transition: 1s cubic-bezier(0.78, 0.01, 0.21, 1);
}

.overlay-links-container {
  text-align: center;
  transform: scale(0);
  opacity: 0;
}

.overlay-links {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.overlay-links li::after {
  content: "";
  display: block;
  width: 100%;
  height: 50px;
  position: absolute;
  background-color: $white;
  transform: translateY(-100%);
}

.overlay-link > a,
button {
  text-decoration: none;
  color: $black;
  h1 {
    font-weight: 700;
  }
}

.overlay-socials {
  display: flex;
  justify-content: space-around;
  width: 50%;
  align-items: baseline;
  margin-right: auto;
  margin-left: auto;
  padding-top: 5em;
}

@media (max-width: 1050px) {
  .nav-socials-section {
    display: none;
  }
  .nav-link-section {
    border: none;
  }
}

@media (width < 800px) {
  .nav-link-section {
    display: none;
  }
  .nav-logo-section {
    border: none;
  }
  .nav-toggle {
    display: flex;
  }
}

@media (width >= 800px) {
  .nav-overlay {
    display: none;
  }
}
