// Colors

$black: #313641;
$white: #f0f5f5;
$blue: #2676a6;
$fade-blue: rgba(12, 98, 199, 0.1);
$fade-white: rgba(255, 255, 255, 0.6);

// Gradients
$fade-black: linear-gradient(
  180deg,
  rgba(49, 54, 65, 0.95) 40%,
  rgba(49, 54, 65, 0) 100%
);
